import observeDOM from "fq-foundation/Scripts/DomObserver";

String.prototype.interpolate = function (params) {
    const names = Object.keys(params);
    const vals = Object.values(params);
    return new Function(...names, `return \`${this}\`;`)(...vals);
}

var eNannyChat = {
    element: ".a-button--enanny",
    leaveMessagePopup: "#eNurseLeaveMessage",
    qa: {
        base: "https://service.force.com/embeddedservice/5.0/esw.min.js",
        secondary: "https://firstquality--pdcopy.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js",
        gslbBaseURL: "https://service.force.com",
        initSet: [
            'https://firstquality--pdcopy.sandbox.my.salesforce.com',
            // 'https://pdcopy-firstquality.cs59.force.com/liveagent',
            'https://firstquality--pdcopy.sandbox.my.salesforce-sites.com/liveagent',
            "https://service.force.com",
            // '00D2C0000008dQJ',
            '00DDL00000CTw3S',
            'Prevail_eNurse_Chat_Live_Agent',
            {
                baseLiveAgentContentURL: 'https://c.la1-c1cs-ia6.salesforceliveagent.com/content',
                deploymentId: '57233000000KzXz',
                buttonId: '57333000000KzdZ',
                baseLiveAgentURL: 'https://d.la1-c1cs-ia6.salesforceliveagent.com/chat',
                eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I5a00000000QlEAI_1771624e525',
                isOfflineSupportEnabled: false
            }
        ],
        settings: {
            displayHelpButton: true,
            language: 'en-US',
            defaultMinimizedText: 'eNanny 24/7',
            disabledMinimizedText: 'Call at 800-584-6721.',
            loadingText: 'Please wait...',
            offlineSupportMinimizedText: '800-584-6721.',
            enabledFeatures: ['LiveAgent'],
            entryFeature: 'LiveAgent'
        }
    },
    prod: {
        base: "https://service.force.com/embeddedservice/5.0/esw.min.js",
        secondary: "https://firstquality.my.salesforce.com/embeddedservice/5.0/esw.min.js",
        gslbBaseURL: "https://service.force.com",
        initSet: [
            'https://firstquality.my.salesforce.com',
            'https://firstquality.secure.force.com/liveagent',
            "https://service.force.com",
            '00D400000007FU7',
            'Prevail_eNurse_Chat_Live_Agent',
            {
                baseLiveAgentContentURL: 'https://c.la3-c2-ia4.salesforceliveagent.com/content',
                deploymentId: '57233000000KzXz',
                buttonId: '57333000000KzdZ',
                baseLiveAgentURL: 'https://d.la3-c2-ia4.salesforceliveagent.com/chat',
                eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I5a00000000QlEAI_1771624e525',
                isOfflineSupportEnabled: false
            }
        ],
        settings: {
            displayHelpButton: true,
            language: 'en-US',
            defaultMinimizedText: 'eNanny 24/7',
            disabledMinimizedText: 'Call at 800-584-6721.',
            loadingText: 'Please wait...',
            offlineSupportMinimizedText: '800-584-6721.',
            enabledFeatures: ['LiveAgent'],
            entryFeature: 'LiveAgent',
            storageDomain: window.location.host,
            targetElement: document.body,
        }
    },
    loadScript: function (url, callback) {
        var script = document.createElement("script")
        script.type = "text/javascript";
        if (script.readyState) {
            script.onreadystatechange = function () {
                if (script.readyState == "loaded" ||
                    script.readyState == "complete") {
                    script.onreadystatechange = null;
                    callback("Success");
                }
            };
        } else {
            script.onload = function () {
                callback("Success");
            };
        }
        script.onerror = function () {
            callback("Error")
        }
        script.src = url;
        document.body.appendChild(script);
    },
    initializeChat: function () {
        var self = this;
        self.loadScript(self[self.env].base, function () {
            $(self.element).addClass('active');
            if (!window.embedded_svc) {
                var s = document.createElement('script');
                s.setAttribute('src', self[self.env].secondary);
                s.onload = function () {
                    self.initESW(null);
                };
                document.body.appendChild(s);
            } else {
                self.initESW(self[self.env].gslbBaseURL);
            }
        });
    },
    init: function () {
        var self = this;
        var urlSplit = window.location.hostname.split(".");
        var subdomain = urlSplit[0];
        if (subdomain == "qa" || subdomain == "dev") {
            self.env = "qa";
        } else {
            self.env = "prod";
        }
        self.initializeChat();
    },
    initESW: function (gslbBaseURL) {
        var self = this;
        $(self.element).removeClass("active");
        embedded_svc.settings = Object.assign(embedded_svc.settings, self[self.env].settings);
        embedded_svc.addEventHandler('afterDestroy', function () {
            $(".embeddedServiceHelpButton").hide();
        });

        embedded_svc.addEventHandler("onAvailability", function (data) {
            console.log("Availability completed", data)
            // $(self.element).data(data.)
            if (data.isAgentAvailable != undefined) {
                // Available
                $(self.element).addClass("active");
            } else {
                // Unavailable
                $(self.element).removeClass("active");
            }
        });

        embedded_svc.addEventHandler('onSettingsCallCompleted', function () {
            if ($(".embeddedServiceHelpButton").find('.message').text() === embedded_svc.settings.defaultMinimizedText) {
                //Available
                $(self.element).addClass("active");
            } else {
                $(self.element).removeClass("active");
                //Unavailable
            }
        });

        embedded_svc.init.apply(embedded_svc, self[self.env].initSet);
        $("a[href*=\"#enanny\"]").on("click", function (ev) {
            $(self.element).trigger("click");
            ev.preventDefault();
            ev.stopPropagation();
            return false;
        });
        $(self.element).on("click", function () {
            $(".embeddedServiceHelpButton .uiButton").trigger("click");
            $(".embeddedServiceHelpButton").show();
        });
        var pageTitle = $("head title").html();
        var dumTitle = "";
        observeDOM(document.body, function () {
            if ($(".modalContainer .stateBody .waitingStateContainer").length > 0 && $(".modalContainer .waitingMessage ").text().indexOf("${Agent}") > -1) {
                let template = $(".modalContainer .waitingMessage").text();
                let result = template.interpolate({
                    Agent: 'Cuties eNanny'
                });
                $(".modalContainer .waitingMessage ").html(result);
                $(".modalContainer .waitingMessage a").attr("href", "tel://+1-800-584-6721").text("800-584-6721");
            }
            else if ($(".modalContainer .stateBody .messageArea").length > 0 && $(".modalContainer .messageArea .plaintextContent ").text().indexOf("Prevail eNurse") > -1) {
                let template = $(".modalContainer .messageArea li:eq(0) .plaintextContent ").text();
                template = template.replace("Prevail eNurse", "Cuties eNanny");
                $(".modalContainer .messageArea li:eq(0) .plaintextContent .uiOutputRichText").html(template);
            }
            else if ($(".modalContainer .messageArea .agentName").text().indexOf("Prevail eNurse") > -1) {
                $(".modalContainer .messageArea .agentName").text("Cuties eNanny");
            }
        });
        observeDOM(document.querySelector('title'), function () {
            if ($("title").html().indexOf("Prevail eNurse") > -1) {
                $("title").html($("title").html().replace("Prevail eNurse", "Cuties eNanny"));
            }
        });
    }
};
eNannyChat.init();